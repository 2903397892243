 import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  VerifyEmailLandingPageComponent,
  AuthenticationOverviewComponent,
  SurveySearchComponent,
  AuthGuard,
  HasSubdomainGuard,
  HasNoSubdomainGuard,
} from '@frontend/common';

import { HomeComponent } from './home/home.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent }, 
  
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  
  { path: 'login', component: AuthenticationOverviewComponent,  data: { appKey: 'culturequestion'/*used in the component until we get environment variables working*/} },
  
  { path: 'register', component: AuthenticationOverviewComponent,  data: { domain: 'culturequestion.com', appKey: 'culturequestion'/*used... as above'*/} },
  
  { path: 'forgot-password', component: AuthenticationOverviewComponent,  data: { domain: 'culturequestion.com', appKey: 'culturequestion'/*used... as above'*/}  },
  
  { path: 'email/verify/:user_id/:hash', component: VerifyEmailLandingPageComponent,  data: { appKey: 'culturequestion'/*used... as above'*/}  },
  
  { path: 'password/reset/:hash/:email', component: AuthenticationOverviewComponent,  data: { appKey: 'culturequestion'/*used... as above'*/, label: 'Reset password', translationKey: 'authentication.new_password'}  },
  
  { path: 'quiz',canActivateChild:[HasSubdomainGuard], loadChildren: () => import('./surveys/surveys.module').then(m => m.SurveysModule),
      data: { label: 'Quiz', translationKey: 'content.quiz' },
  },
  
  { path: 'design', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common/design').then(m => m.DesignModule), data: { label: 'Design', translationKey: 'navigation.design', labelSource: null} },
  
  { path: 'community/search', canActivateChild:[HasNoSubdomainGuard], loadChildren: () => import('@frontend/common').then(m => m.CommunitySearchModule), data: { label: 'Community', translationKey: 'navigation.community', labelSource: null} },

  { path: 'community', redirectTo: 'community/search' },

  { path: 'search', component: SurveySearchComponent,
      data: { label: 'Search', translationKey: 'navigation.search' , path_to_surveys : '../quiz/', filter_category : 'cultures', filter_type : 'knowledge', },
  },
  { path: 'account', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.UserAccountModule), data: { label: 'Account', translationKey: 'navigation.account', labelSource: null} },
  
  { path: 'hubs', canActivate:[AuthGuard], loadChildren: () => import('@frontend/common').then(m => m.HubsModule), data: { label: 'Hubs', translationKey: 'navigation.hubs', labelSource: null} },
  {
    path: 'coming-soon',
    loadChildren: () =>
      import('@frontend/shared').then((o) => o.ComingSoonModule),
  },
  { path: '**', redirectTo: '' }, // Add 404 page here
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
